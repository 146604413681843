import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import './char-guide.scss';
import { NikkeCharacter } from '../../../modules/nikke/common/components/nikke-character';
import { Alert, Col, Row } from 'react-bootstrap';

const NikkeGuidesMoran: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page char-guide'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Moran guide & review</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_moran.jpg"
            alt="Wishlist"
          />
        </div>
        <div className="page-details">
          <h1>Moran guide & review</h1>
          <h2>A guide & review for Moran in NIKKE: Goddess of Victory.</h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Banner" />
        <StaticImage
          src="../../../images/nikke/generic/banner_moran.jpg"
          alt="Moran"
        />
        <p>
          <strong>Moran</strong> banner will be available:{' '}
          <strong>1/25 12:00 ~ 2/8 04:59 (UTC+9)</strong>
        </p>
        <SectionHeader title="Foreword" />
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="moran" enablePopover />
        </div>
        <p>
          Moran, the naive Queen of Underworld, has descended from her majestic
          throne! While she may be an oddball, treat her seriously as she is
          fearsome when she wants to be. As long as you are on her good side,
          she will do everything she can to protect you.... literally from story
          to gameplay.
        </p>
        <p>
          She is Guillotine's best friend in Solo Raids. Unlike Noise, she is
          the only dependable B1 taunter with lifesteal and high HP who does not
          directly interfere with Guillotine's core mechanics — HP loss. Paired
          with certain units, her existence allows Guillotine to shine once
          again. In PvP, she has a formidable presence. A continuous full-screen
          taunt & damage reduction, complemented with notable single target
          damage & lifesteal (disclaimer: only with SAnis), makes her not only a
          shield but also a sword.
        </p>
        <SectionHeader title="Kit Analysis" />
        <p>
          When Moran's kit dropped for the first time, many speculated that she
          would have outstanding damage, even as a 40s Defender B1. However,
          this was proven untrue after some tests. She still did some damage,
          however, so in game modes like Solo Raid where every million matters,
          she indeed does have an advantage. Unfortunately, her damage only
          exists when paired with certain units like SAnis, who boosts the ATK
          of all Electric units in team during Full Burst.
        </p>
        <p>
          If offense is not Moran's specialty, then what is it? Well, her
          taunting ability is ridiculous. She has quite high uptime on taunts
          outside her Burst Skill and can guarantee full taunt when using Burst
          Skill. She is not too fat to i-frame and has a makeshift
          indomitability that takes the form of HP Recovery whenever she goes
          below 20% HP. To make this more reliable, she fortifies her DEF with
          HP loss, gradually turning into a rock the closer she is to death.
          Combine that with the Damage Reduction on Burst and Lifesteal (w/
          SAnis), and she becomes a force to be reckoned with.
        </p>
        <h6>Skill 1</h6>
        <blockquote>
          <p>■ Activates at the start of the battle. Affects self.</p>
          <p>DEF ▲ 3.51% with every 1% of HP loss.</p>
          <p>
            ■ Activates when landing 5 normal attacks when changing the weapon
            in use. Affects the target.
          </p>
          <p>Deals 47.18% of final ATK as additional damage.</p>
        </blockquote>
        <p>
          Moran's Skill 1 consists of two separate components. Its offensive
          half defines how much damage she will deal every 5 shots during her
          Burst Skill. The scaling is quite low, and without enough buffs, it is
          only useful in Solo Raid where every ounce of damage matters.
          Meanwhile, the other half boosts her DEF for every 1% HP lost. For
          some time, we have been blinded by the impression that DEF boost is
          bad. Well, in Moran's case, the value of the buff is actually big
          enough to turn this around. Furthermore, it synchronizes well with her
          Skill 2, helping it activate more consistently.
        </p>
        <h6>Skill 2</h6>
        <blockquote>
          <p>
            ■ Activates when firing the final bullet. Affects 3 enemy units with
            the highest ATK.
          </p>
          <p>Taunt for 4 sec.</p>
          <p>■ Activates when HP falls below 20%. Affects self.</p>
          <p>
            Effect changes according to the number of activation time.
            Perseverance: Effect of each phase does not stack.
          </p>
          <p>Once: Max HP ▲ 91% for 3 sec. Activates once per battle.</p>
          <p>Twice: Max HP ▲ 69.84% for 3 sec. Activates once per battle.</p>
          <p>Three: Max HP ▲ 51.09% for 3 sec. Activates once per battle.</p>
        </blockquote>
        <p>
          When not in Burst Skill, Moran relies on activating her Skill 2 to
          taunt enemies. It is similar to Makima's and Tia's, as in the taunt is
          not permanent and only sets in periodically. Unlike them, however, it
          is dependent on last bullet, so the interval cannot be shortened
          without Ammo Reduction or Reload Speed buffs. The good thing? Well, it
          can be un-triggered at will. What we mean is that, if you don't want
          her to taunt, then reload before she fires her last bullet.
        </p>
        <p>
          Additionally, what's interesting about Moran's Skill 2 is its second
          component. Whenever she drops below 20%, she recovers a set amount of
          HP, triggering up to 3 times. This basically means she has a much
          higher effective MAX HP compared to other units. This skill is of
          immense importance to her kit as this her major tanking ability.
        </p>
        <Alert variant="primary">
          <p>
            The HP Recovery will not trigger if she is insta-killed from above
            20%, so in some cases, you may have to adjust your tactics.
          </p>
        </Alert>
        <h6>Burst</h6>
        <blockquote>
          <p>■ Affects self.</p>
          <p>Change the Weapon in use:</p>
          <p>Damage: 14.7% of final ATK</p>
          <p>Lasts for: 10 sec</p>
          <p>Additional Effects:</p>
          <p>Recovers 36.14% of attack damage as HP for 10 sec. </p>
          <p>Attract: Taunt all enemies for 10 sec.</p>
          <p>Note: Cannot take cover while casting Burst skill.</p>
          <p>■ Affects all allies.</p>
          <p>Damage Taken ▼ 35.14% for 10 sec.</p>
          <p>DEF ▲ 14.85% of caster's DEF for 10 sec.</p>
        </blockquote>
        <p>
          Her Burst Skill is unique in a way that it provides a notable teamwide
          Damage Reduction for 10s, alongside a full-screen taunt for the same
          duration. This lets your team absorb some AoE damage, whereas Moran
          herself will tank all the single-target attacks. This can be pretty
          useful on some campaign stages, bosses with unpredictable targeting,
          and most importantly: PvP Arena. The DEF boost is minuscule and should
          not be affected by Skill 1 because it is based on caster's stats.
        </p>
        <p>
          Another distinguishing characteristic of her Burst Skill is her weapon
          change, which may or may not be something that you actually want.
          During Burst Skill, she switches from AR to infinite-ammo SMG, which
          means she can continually fire for 10s and also several seconds after
          the Burst ends (AR fully reloaded). This can be particularly useful in
          PvP, where reloading will have enemy skip her.
        </p>
        <p>
          Now, while wielding SMG, she also inflicts an instance of damage to
          the enemy every 5 shots (from Skill 1). This results in some bonus
          damage + lifesteal, especially in SR and when paired with caster's ATK
          offensive buffs, such as Mast (DEF loss) & SAnis. Otherwise, they are
          too small to bring any impact.
        </p>
        <Alert variant="primary">
          <p>
            Do note that when using Burst Skill, she cannot hide in cover, so
            you cannot i-frame! Activate wisely in PvE.
          </p>
        </Alert>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="Usage" />
        <p>
          Moran will probably not have much use in Campaign, Tribe Tower, or
          Union Raids, but she might see use in Solo Raids and PvP.
        </p>
        <h6>Raids</h6>
        <p>
          Will likely be prominent against Water Bosses (weak to Electric) as
          her best team consists of SAnis and Guillotine. This is particularly
          true when the boss resides in MG's range and possesses a small core.
          They may still see usage outside their elemental advantage.
        </p>
        <h6>PVP</h6>
        <p>
          Moran proves herself to be quite useful in PvP. Let's start with the
          basics. She is a Defender who posseses a "long taunt" on burst,
          becoming only the 4th Nikke to have a long taunt duration. Noah, Nero
          and Ludmilla are the other 3 "long taunt" Defenders. If you've used
          Noah or Nero, you are probably aware that this means she can be paired
          with Biscuit to counter single-target focus Nikkes like Red Hood,
          Alice or SGs like Sugar. Moran + Noah + Biscuit have a special
          combination here, where they are able to taunt both bullets of SAnis's
          Skill 2, an excellent counter to the opponent SAnis.
        </p>
        <p>
          Moran also provides a Damage Reduction for all allies on burst, which
          means she can act as a soft counter to unbuffed AoE attacks from
          Scarlet, Harran, or Maiden. This means she can also act as a "worse"
          Noise, since it is pretty much a weaker block instead of heals. When
          your team is severely injured from the AoE attack, use a healer to
          recover your team's HP while Moran tanks bullets for the team (with
          her taunt). Since Moran is a B1 unit, she also allows Biscuit to
          Burst, empowering your Summer Anis with ATK buff and lifesteal!
        </p>
        <Alert variant="primary">
          <p>
            In conclusion, Moran offers a soft AoE block like Noise's (but
            instead of healing allies, she tanks damage herself). She also has a
            long taunt like Nero's, can be paired with Biscuit (especially
            useful with SAnis as B3), and possesses virtual indomitability like
            Makima's. This makes her a strong choice for PvP meta teams.
          </p>
        </Alert>
        <SectionHeader title="Investments" />
        <h5>Gear Investments</h5>
        <p>
          As a buffed defender, investing in her OL for Arena is definitely
          worthwhile. Not only do you boost her CP, but you also increase her
          MAX HP and DEF. This will allow her to tank more effectively and rely
          less on RNG. In PvE, Hit Rate may increase her damage consistency.
        </p>
        <Alert variant="primary">
          <p>
            For the OL lines, aim for Hit Rate & ATK (even if it is less
            effective). In PvE, you may benefit more from Elemental Damage,
            especially when paired with SAnis and/or Mast.
          </p>
        </Alert>
        <h5>Skill Investments</h5>
        <p>
          Her skills are all useful, and all of them contribute to her survival
          in different ways.
        </p>
        <ul>
          <li>
            <strong>Skill 1: 4-7</strong>
          </li>
          <ul>
            <li>
              Leave it at 4, unless your wallet is thick, in which case you may
              want to max this skill. This is especially true if you care a lot
              about Solo Raid. For cost efficiency, nevertheless, we suggest not
              upgrading beyond 7.
            </li>
          </ul>
        </ul>
        <ul>
          <li>
            <strong>Skill 2: 4-10</strong>
          </li>
          <ul>
            <li>
              Usable at 4, but you may want to invest a bit more to make it
              ideal. In fact, there is no harm in raising this to 10, epecially
              if your wallet is thick. Sometimes, that small HP recovery boost
              may be crucial.
            </li>
          </ul>
          <li>
            <strong>Burst: 4-10</strong>
          </li>
          <ul>
            <li>
              Again, she is already useful at 4, and any levels above that are a
              bonus. However, if you want more Damage Reduction especially in
              PvP, feel free to swipe your card! If you want more damage from
              her Burst Skill, also swipe your card! If you want more lifesteal,
              swipe it even harder! Your credit card is the limit. Jokes aside,
              this is a strong skill and can be extremely useful for PvP.
            </li>
          </ul>
        </ul>
        <h5>Cube Investments</h5>
        <StaticImage
          src="../../../images/nikke/generic/cube_3_sm.png"
          alt="Guide"
        />
        <p>
          For more taunting outside Burst Skill, use Resilience. For higher HP,
          use Vigor. If you feel like she taunts too much outside Full Burst and
          want to dial it back a bit, try Bastion. However, this is not
          recommended! You can still use Resilience and manual her if you are
          worried.
        </p>
        <Alert variant="primary">
          <p>
            In PvP, you will depend more on her Burst Skill to taunt, in which
            case Vigor is always the better choice.
          </p>
        </Alert>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="PVE Team Compositions" />
        <h5>Team #1</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="sakura" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="moran" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="mast" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter
                mode="icon"
                slug="sparkling-summer-anis"
                enablePopover
              />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="guillotine" enablePopover />
            </div>
          </div>
        </div>
        <p>
          This is perhaps her best PvE team right now and the only recommended
          one at that, a direct upgrade to the Noise variant where you are
          forced to heal Guillotine. It also avoids the use of SHelm, which
          practically offers nothing but CDR against non-Electric bosses. SAnis
          and Mast help increase her ATK and in turn her Burst Skill damage &
          lifesteal. Moran also taunts reliably, so Guillotine can concentrate
          on unloading her insane damage at lower HP without the fear of dying.
          Nevertheless, if there is too much AoE damage, this team may not work.
        </p>
        <SectionHeader title="PVP Team Compositions" />
        <h5>Team #1 - Moran allowing Biscuit to Burst</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="moran" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="biscuit" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter
                mode="icon"
                slug="sparkling-summer-anis"
                enablePopover
              />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="anis" enablePopover />
              <NikkeCharacter mode="icon" slug="centi" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-flex">Burst Flex</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="drake" enablePopover />
              <NikkeCharacter mode="icon" slug="maiden" enablePopover />
              <NikkeCharacter mode="icon" slug="pepper" enablePopover />
            </div>
          </div>
        </div>
        <p>
          When using Pepper, place her in Position 2. This is probably one of
          the best Moran teams, and it's hard to do better than this one. The
          synergy is top tier. Once Moran allows Biscuit to burst, the survival
          of your SAnis is nearly guaranteed. The speed of this team is 3RL
          (check out our{' '}
          <Link to="/nikke/guides/pvp-burst">PvP Burst Generation Guide</Link>{' '}
          for more info on PvP teams), which means it can handle a lot of teams
          despite being weak to Scarlet.
        </p>
        <h5>Team #2 - Noah AoE block</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="moran" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="noah" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="biscuit" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter
                mode="icon"
                slug="sparkling-summer-anis"
                enablePopover
              />
              <NikkeCharacter
                mode="icon"
                slug="scarlet-black-shadow"
                enablePopover
              />
              <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-flex">Burst Flex</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="anis" enablePopover />
              <NikkeCharacter mode="icon" slug="centi" enablePopover />
              <NikkeCharacter mode="icon" slug="drake" enablePopover />
            </div>
          </div>
        </div>
        <p>
          Slower team, overall weak against SBS, and also weak against most
          Scarlet teams if using SAnis. If using SBS/Red Hood, it is strong
          against 3RL-4RL non Noah-Biscuit combo opponents. Moran and Noah tank
          for the team while Anis: Sparkling Summer, Scarlet: Black Shadow or
          Red Hood dish out high DPS. Sugar is also an alternative if you lack
          other units.
        </p>
        <h5>Team #3 - Moran SG Team</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="moran" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="biscuit" enablePopover />
              <NikkeCharacter mode="icon" slug="viper" enablePopover />
              <NikkeCharacter mode="icon" slug="poli" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-flex">Burst Flex</h5>
            <div className="placeholder-box">SG</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-flex">Burst Flex</h5>
            <div className="placeholder-box">SG</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-flex">Burst Flex</h5>
            <div className="placeholder-box">SG</div>
          </div>
        </div>
        <p>
          Keep in mind that the SGs we expect to use are Clip Shotguns like
          Sugar. You can find more about Weapon Types for PvP in our our{' '}
          <Link to="/nikke/guides/pvp-burst">PvP Burst Generation Guide</Link>.
          This is a simple team really. Moran protects Position 1, and the rest
          of the team are SG units who are capable of doing lethal damage to the
          opponent.
        </p>
        <SectionHeader title="Should You Pull" />
        <p>
          Moran is one of the missing piece to building the perfect Electric
          team, together with SAnis and Guillotine. She also sees tons of usage
          in PvP and offers a unique specialty that no other units can. If you
          care a lot about PvP or Solo Raid, one copy should suffice. Otherwise,
          you can save your wallet and wait for the next unit, which will most
          likely be a DPS as it coincides with Solo Raid.
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="Pros & Cons" />
        <Row className="pros-cons">
          <Col sm="12" md="6">
            <div className="box pros">
              <h5>Pros</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>Respectable tanking ability.</li>
                  <li>
                    Great pair for Guillotine in PvE because she has no heal.
                  </li>
                  <li>
                    Possesses long taunts; synergizes well with Biscuit in PvP.
                  </li>
                  <li>Last bullet taunt is controllable.</li>
                  <li>Soft counter to AoE in PvP.</li>
                  <li>
                    Lifesteal with SAnis's buff allows her to recover some HP
                    hack.
                  </li>
                </ul>
              </div>
            </div>
          </Col>
          <Col sm="12" md="6">
            <div className="box cons">
              <h5>Cons</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>Cannot cover during Burst Skill.</li>
                  <li>
                    Can die to one shot if an opponent rapture (or Nikke in PvP)
                    does enough damage to reduce her HP from &gt;20% to 0%
                    instantly.
                  </li>
                  <li>Low Burst Gen hurts PvP team-building.</li>
                  <li>
                    Her protection is DMG Reduction, not healing like Noise, so
                    she might not be able to protect your team from constant AoE
                    (Bronchus).
                  </li>
                  <li>
                    DMG is below expectation, making her lifesteal underwhelming
                    too.
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
        <SectionHeader title="Final ratings" />
        <div className="ratings-container">
          <div className="ratings-box a">
            <h6>A</h6>
            <p>Story (High Deficit)</p>
          </div>
          <div className="ratings-box b">
            <h6>B</h6>
            <p>Story (Low Deficit)</p>
          </div>
          <div className="ratings-box a">
            <h6>A</h6>
            <p>Boss (Solo)</p>
          </div>
          <div className="ratings-box a">
            <h6>A</h6>
            <p>Boss (Adds)</p>
          </div>
          <div className="ratings-box ss">
            <h6>SS</h6>
            <p>PVP</p>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default NikkeGuidesMoran;

export const Head: React.FC = () => (
  <Seo
    title="Moran guide & review | NIKKE | Prydwen Institute"
    description="A guide & review for Moran in NIKKE: Goddess of Victory."
    game="nikke"
  />
);
